import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4a20f362"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "error-container"
};
const _hoisted_2 = {
  class: "error-content"
};
const _hoisted_3 = {
  class: "pic-error"
};
const _hoisted_4 = {
  class: "error"
};
const _hoisted_5 = {
  class: "error-oops"
};
const _hoisted_6 = {
  class: "error-headline"
};
const _hoisted_7 = {
  class: "error-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
    gutter: 20
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      lg: 12,
      md: 12,
      sm: 24,
      xl: 12,
      xs: 24
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_image, {
        class: "pic-error-parent",
        src: require('@/assets/images/404.png')
      }, null, 8, ["src"])])]),
      _: 1
    }), _createVNode(_component_el_col, {
      lg: 12,
      md: 12,
      sm: 24,
      xl: 12,
      xs: 24
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.oops), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.headline), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.info), 1)])]),
      _: 1
    })]),
    _: 1
  })])]);
}