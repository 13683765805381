import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, reactive, toRefs } from 'vue';
import { ElImage, ElRow, ElCol } from 'element-plus';
export default {
  name: 'Page404',
  components: {
    ElImage,
    ElRow,
    ElCol
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      timeout: 5,
      oops: '抱歉!',
      headline: '当前页面不存在...',
      info: '请检查您输入的网址是否正确。',
      //，或点击下面的按钮返回首页。
      btn: '返回首页',
      timer: 0
    });
    onBeforeRouteLeave((to, from, next) => {
      clearInterval(state.timer);
      next();
    });
    onBeforeMount(() => {
      //timeChange();
    });
    const timeChange = () => {
      state.timer = setInterval(() => {
        if (state.timeout) {
          state.timeout--;
        } else {
          router.push('/');
          clearInterval(state.timer);
        }
      }, 1000);
    };
    return {
      ...toRefs(state),
      timeChange
    };
  }
};